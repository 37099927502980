import React from 'react';
import { ReferenceField, TextField, ReferenceInput, AutocompleteInput } from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
} from '@api-platform/admin';


const roles = [
    { name: "ACCOUNT_MANAGER", cname: "manager", value: ["ACCOUNT_MANAGER"] },
    { name: "ACCOUNT_PLAYER", cname: 'jugador', value: ["ACCOUNT_PLAYER"] },
];

export const PermissionsList = props => (
    <div>
        <ListGuesser {...props}>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="email"/>
            </ReferenceField>
            <ReferenceField label="Account" source="account" reference="accounts">
                <TextField source="name"/>
            </ReferenceField>
            {/* While deprecated fields are hidden by default, using an explicit FieldGuesser component allows to add them back. */}
            <FieldGuesser source="grants" />
        </ListGuesser>
    </div>

);

export const PermissionsCreate = props => (
  <CreateGuesser {...props}>
    <ReferenceInput
      source="account"
      reference="accounts"
      label="Account"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
      <ReferenceInput
          source="user"
          reference="users"
          label="User"
          filterToQuery={searchText => ({ email: searchText })}
      >
          <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <AutocompleteInput
          source="grants"
          choices={roles}
          optionText="cname"
          optionValue="value"
      />

  </CreateGuesser>
);

