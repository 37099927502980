import React from 'react';
import { DateTimeInput , DateField, FunctionField, ReferenceField, TextField, ReferenceInput, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    ShowGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const BookingEdit = props => (
    <EditGuesser {...props}>
        <DateField source={"start"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true}  />
        <FieldGuesser source={"duration"} addLabel={true} />
        <ReferenceInput
            source="user"
            reference="users"
            label="User"
            filterToQuery={searchText => ({ email: searchText })}
        >
            <AutocompleteInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput
            source="account"
            reference="accounts"
            label="Account"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);



export const BookingList = props => (
    <ListGuesser {...props}>
        <DateField source={"start"} label={"Day"} options={{ year:"numeric", month: 'narrow', day: 'numeric' }}/>
        <DateField source={"start"} label={"Hour"} options={{ hour: '2-digit', minute: '2-digit' }} showTime/>
        <FieldGuesser source={"duration"} />
        <ReferenceField label="User" source="user" reference="users">
            <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label="Account" source="account" reference="accounts">
            <TextField source="name"/>
        </ReferenceField>

    </ListGuesser>
);

export const BookingShow = props => (
    <ShowGuesser {...props}>
        <DateField source={"start"} options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} showTime addLabel={true} />
        <FieldGuesser source={"duration"} addLabel={true} />
        <ReferenceField label="User" source="user" reference="users">
            <TextField source="email"/>
        </ReferenceField>
        <ReferenceField label="Account" source="account" reference="accounts">
            <TextField source="name"/>
        </ReferenceField>

    </ShowGuesser>
);

