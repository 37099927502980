import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
    dataProvider as baseDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser  ,
} from '@api-platform/admin';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';

import { Admin, Resource } from 'react-admin';

import Dashboard from './Dashboard';

import {UsersList, UsersCreate, UserEdit } from './Users';
import {PermissionsCreate, PermissionsList} from "./Permissions";
import {BookingEdit, BookingList, BookingShow} from "./Bookings";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser);


export default props => (
    <HydraAdmin
        apiDocumentationParser={ apiDocumentationParser }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        dashboard={Dashboard}
    >
        <ResourceGuesser name="users" create={UsersCreate} list={UsersList} edit={UserEdit}/>
        <ResourceGuesser name="accounts"/>
        <ResourceGuesser name="permissions" create={PermissionsCreate} list={PermissionsList}/>
        <ResourceGuesser name="bookings" list={BookingList} edit={BookingEdit} show={BookingShow}/>

    </HydraAdmin>

);
